const Content = {
  About: {
    imageSrc: null,
    headLine: `Книги`,
    text: `Есть много вариантов Lorem Ipsum, 
          но большинство из них имеет не всегда приемлемые модификации, 
          например, юмористические вставки или слова, 
          которые даже отдалённо не напоминают латынь. 
          Если вам нужен Lorem Ipsum для серьёзного проекта, 
          вы наверняка не хотите какой-нибудь шутки, 
          скрытой в середине абзаца.`,
    linkTo: null,
  },
  Books: [
    {
      imageSrc: 'masha.jpg',
      linkTo: null,
      headLine: `Маша блин`,
      text: `Есть много вариантов Lorem Ipsum, 
          но большинство из них имеет не всегда приемлемые модификации, 
          например, юмористические вставки или слова, 
          которые даже отдалённо не напоминают латынь. `,
    },
    {
      imageSrc: 'grisha.jpg',
      linkTo: null,
      headLine: `Гриша блин`,
      text: `Также все другие известные генераторы Lorem Ipsum 
            используют один и тот же текст, который они просто повторяют, 
            пока не достигнут нужный объём.`,
    },
  ],
};

export default Content;
